<template>
  <div>
    <h4>NOTA DE ENTRADA {{ inputId }}</h4>
    <div class="row">
      <div class="col">
        <!-- <Show :input="reg"></Show> -->
        <FormCreate ref="elFormCreate" :disabled="true"></FormCreate>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-md-4"> -->
      <!--   <div class="card mb-3"> -->
      <!--     <div class="card-body"> -->
      <!--       INGRESO POR CODIGO -->
      <!--       <FastCode -->
      <!--         ref="fastCode" -->
      <!--         @submitted=" -->
      <!--           $refs.tr.gl(); -->
      <!--           $refs.fastCode.reset(); -->
      <!--         " -->
      <!--         @preInput="$refs.trLoading.state(true)" -->
      <!--         :inputId="inputId" -->
      <!--         :money_code="reg.money_code" -->
      <!--       ></FastCode> -->
      <!--     </div> -->
      <!--   </div> -->

      <!--   <FormInput ref="formInput" :disabled="true"></FormInput> -->
      <!-- </div> -->
      <div class="col">
        <app-table-registers ref="tr" :getList="getList">
          <template slot="tl">
            <div class="col">
              <button
                class="btn btn-primary"
                @click="
                  $refs.formDet.reset();
                  $refs.dFormDet.show();
                "
              >
                <i class="fas fa-plus"></i>
                Agregar detalle
              </button>
            </div>
          </template>
          <template slot="table">
            <thead>
              <tr>
                <th></th>
                <th>PRODUCTO</th>
                <th>CANTIDAD</th>
                <th>PRECIO UNITARIO</th>
                <th>SUB TOTAL</th>
                <th>LOTE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <app-tr-loading v-show="loading" :colspan="6"></app-tr-loading>
              <tr v-for="l in list.data" :key="l.id">
                <td>
                  <img
                    class="product_img"
                    :src="l.product_file_id | fileUrl"
                    alt=""
                  />
                </td>
                <td>{{ l.product_name }}</td>
                <td>{{ l.quantity }}</td>
                <td class="text-nowrap">
                  {{ l.unit_price | money }}
                </td>
                <td class="text-nowrap">
                  {{ (l.unit_price * l.quantity) | money }}
                </td>
                <td>{{ l.lot }}</td>
                <td>
                  <div class="btn-group">
                    <button
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.dDelDet.show();
                        $refs.dDelDet.val = l;
                      "
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-basic ref="dFormDet">
      <FormDetails
        ref="formDet"
        :inputId="inputId"
        @submitted="
          $refs.dFormDet.hide();
          $refs.tr.gl();
        "
      ></FormDetails>
    </app-modal-basic>

    <app-modal-yn
      ref="dDelDet"
      @yes="
        $refs.dDelDet.hide();
        deleteDet($refs.dDelDet.val.id);
      "
      @no="$refs.dDelDet.hide()"
    >
      <p>
        Eliminar detalle:
        <span v-if="$refs.dDelDet && $refs.dDelDet.val">
          <b>{{ $refs.dDelDet.val.quantity }}</b> unidades de
          <b>{{ $refs.dDelDet.val.product_name }}</b>
        </span>
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
import { LogisticService } from "../service";
import FormDetails from "./FormDetails.vue";
import FormCreate from "./FormCreate.vue";
//import FastCode from "./FastCode";
// import Show from "./Show.vue";
export default {
  components: {
    //FastCode,
    // SelectStore,
    FormCreate,
    FormDetails
    // Show
    //FormInput
  },

  // directives
  // filters

  props: {
    inputId: {}
  },

  data: () => ({
    list: {},
    // reg: {},
    loading: false
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    LogisticService.getInput(this.inputId).then((res) => {
      //this.reg = res;
      this.$refs.elFormCreate.setValue(res);
      // this.$refs.selSto.setValueFromId(this.reg.store_id);
    });
  },

  methods: {
    deleteDet(id) {
      LogisticService.deleteInput(id).then(() => this.$refs.tr.gl());
    },
    getList({ page, search }) {
      this.list.data = [];
      return new Promise((rsv) => {
        this.loading = true;
        LogisticService.getInputDetails({
          page,
          search,
          input_id: this.inputId
        }).then((res) => {
          this.list = res;
          this.loading = false;
          // this.$refs.trLoading.state(false);
          rsv(res);
        });
      });
    }
  }
};
</script>

<style scoped>
.product_img {
  max-width: 4rem;
  max-height: 2rem;
}
</style>
