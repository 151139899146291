<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Producto*</label>
      <SelectProduct
        ref="selPro"
        typeId="1"
        v-model="reg.product_id"
      ></SelectProduct>
      <app-small-form-errors
        :errors="errors.product_id"
      ></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Cantidad*</label>
      <input
        type="number"
        class="form-control"
        required
        v-model="reg.quantity"
        min="1"
      />
      <app-small-form-errors :errors="errors.quantity"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Precio unitario*</label>
      <app-input-money
        :selCurrDis="true"
        :quantity.sync="reg.unit_price"
        :moneyCode="money_code"
      ></app-input-money>
      <app-small-form-errors
        :errors="errors.unit_price"
      ></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Fecha de Vencimiento</label>
      <!-- <app-select-date v-model="reg.expiration" :maxYear="thisYear + 10"></app-select-date> -->
      <app-datepicker v-model="reg.expiration"></app-datepicker>
    </div>
    <div class="form-group">
      <label for>Fecha de Fabricación</label>
      <!-- <app-select-date v-model="reg.fabrication"></app-select-date> -->
      <app-datepicker v-model="reg.fabrication"></app-datepicker>
    </div>
    <div class="form-group">
      <label for>Lote</label>
      <input type="text" class="form-control" v-model="reg.lot" />
    </div>
    <app-button-submit
      @click="save()"
      :disabled="errors"
      :loading="sending"
    ></app-button-submit>
  </form>
</template>

<script>
import validate from "validate.js";
import SelectProduct from "../../store-module/products/Select";
import { LogisticService } from "../service";

const formRules = {
  product_id: { presence: { message: "Requerido" } },
  quantity: { presence: { message: "Requerido" } },
  unit_price: { presence: { message: "Requerido" } }
};

export default {
  components: { SelectProduct },
  props: {
    inputId: {},
    money_code: {
      default: 1
    }
  },
  data: () => ({
    reg: {},
    thisYear: new Date().getFullYear(),
    sending: false
  }),
  computed: {
    errors() {
      let err = validate(this.reg, formRules, { fullMessages: false });
      return err ? err : false;
    }
  },
  mounted() {
    //
  },
  methods: {
    async save() {
      this.sending = true;
      try {
        let res = await LogisticService.saveInputDetail({
          ...this.reg,
          input_id: this.inputId
        });
        this.$emit("submitted", res);
      } catch (e) {
        //
      }
      this.sending = false;
    },
    reset() {
      this.reg = {};
      this.$refs.selPro.reset();
    }
  }
};
</script>

<style></style>
